<template>
  <ui-component-modal
    modalTitle=""
    modalSize="large"
    :onClickCancel="onClickCancel"
    :hideSaveButton="true"
    :showModal="showModal"
    @closeModal="onClickCancel"
    :buttonLabelCancel="
      $t('Components.Reservation.MailLog.Label_Close')
    "
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="title is-size-4">{{ mailLog.Subject }}</div>

      <div>
        <div class="has-text-weight-bold">{{ mailLog.To }}</div>
        <div class="is-size-7">{{ mailLog.From }}</div>
      </div>

      <div v-html="mailLog.Body" class="has-margin-top"></div>
    </template>
  </ui-component-modal>
</template>

<script>

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    mailLog: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {
    save() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
      }
    },
  },
}
</script>
